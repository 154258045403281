import "$styles/index.css"
import "$styles/syntax-highlighting.css"
import "$styles/prism.css"
// import "$styles/synthwave-84.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import "$javascript/prism"

console.info("Bridgetown is loaded!")
